import React, { useEffect, useRef, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import ProductStoreDatatable from './ProductStoreDatatable';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sourceStoreListeApi } from '../../redux/slices/storeSlice';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import toast from 'react-hot-toast';

const ProductStore = () => {
    const { state } = useLocation();
    const [sort_order, setSort_order] = useState('desc');
    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order
    };
    const { sourceStoreList, verifiedStoreData, isSuccess } = useSelector((state) => state.store);
    const [queryString, setQueryString] = useState(`page=${table_data.page}&per_page=${table_data.per_page}`);

    const dispatch = useDispatch();

    const [labelName, setLabelName] = useState('');
    const [destValue, setDestValue] = useState(null);
    // console.log(destValue,"destValue new");
    const [destChangeValue, setDestChangeValue] = useState(false);
    const toast = useRef(null);

    const storeLabel = sourceStoreList?.results?.map((element) => {
        const storeObj = { storeName: element.store_name, storeId: element.store_id };
        return storeObj;
    });

    const getSourceStoreName = sourceStoreList?.results?.filter((ele) => ele.store_type == 'destination');

    const [destItem, setDestItem] = useState([]);
    var destItems = [];
    // sourceStoreList?.results?.map((element) => {
    //     items.push({ label: element.source_store_name, value: element.source_store_name });
    // });
    const handleValueChange = (e) => {
        setDestValue(e.target.value);
        setDestChangeValue(true);
        destItem?.map((ele) => {
            if (ele.value == e.value) {
                return setLabelName(ele.label);
            }
        });
        toast.current.show({ severity: 'info', detail: 'Fetching products from Source store, Please wait patiently', life: 5000 });
    };
    const getQueryParam = () => {
        if (verifiedStoreData?.is_verified) {
            return `${queryString}&store_id=${verifiedStoreData.store_id}&store_type=${verifiedStoreData.store_type}`;
        } else {
            return queryString;
        }
    };
    useEffect(() => {
        dispatch(sourceStoreListeApi(`${getQueryParam()}`));
        if (state?.storeId) {
            setDestValue(state?.storeId);
        }
    }, []);

    useEffect(() => {
        const arr = [];
        sourceStoreList?.results?.forEach((ele) => {
            // if (ele?.is_connected === 'approved' && ele?.store_id === state?.storeId) {
            arr.push({ label: ele?.store_name, value: ele?.store_id });
            setDestChangeValue(true);
            setLabelName(ele.store_name);
            // }
        });
        setDestItem(arr);
        if (state?.storeId) {
            setDestValue(state?.storeId);
        }
    }, [isSuccess]);

    // useEffect(() => {
    //     if (sourceStoreList?.results?.length > 0) {
    //         sourceStoreList?.results?.map((element) => {
    //             // console.log(element.is_connected, 'ele');
    //             if (element.is_connected == 'approved') {
    //                 destItems.push({ label: element.store_name, value: element.source_id });
    //                 return setDestItem(destItems);
    //             }
    //         });
    //     }
    // }, [sourceStoreList?.results]);

    const legendSourceTemplate = (
        <div className="flex align-items-center text-primary">
            <span className="font-bold text-lg text-color-secondary">Source store</span>
        </div>
    );

    const legendDestinationTemplate = (
        <div className="flex align-items-center text-primary">
            <span className="font-bold text-lg text-color-secondary">Select a Destination store</span>
        </div>
    );
    const breadcrumbItems = [{ label: 'Stone Share' }, { label: 'Stone Share Products' }];
    return (
        <div>
            <div className="col px-0 pb-1 pt-0">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Products</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/dashboard' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            {/* <h5 className="font-bold mt-0 text-lg text-color-secondary">You can Sync Source store products to the destination store. And also do Map, Product sync, and Product View.</h5> */}
            <div className="grid p-fluid">
                <Toast ref={toast} />
                <div className="col-12 md:col-3 product-store-content">
                    {/* <Fieldset legend={legendSourceTemplate}> */}
                    {/* <Dropdown id="sourceSelect" options={items} value={value} onChange={(e) => getDestinationStore(e)} optionLabel="label" /> */}
                    <label htmlFor="source_store" className="text-lg font-bold">
                        Destination store
                    </label>
                    <InputText className="text-xl font-bold mt-1 capitalize" defaultValue={verifiedStoreData?.store_name} style={{ color: 'black' }} disabled />
                    {/* </Fieldset> */}
                </div>
                <div className="col-12 md:col-3">
                    {/* <Fieldset legend={legendDestinationTemplate}> */}
                    <span className="p-float-label mt-5">
                        <Dropdown
                            id="destiantionSelect"
                            style={{ padding: '3px' }}
                            options={destItem}
                            value={destValue}
                            optionLabel="label"
                            optionValue="value"
                            onChange={(e) => {
                                handleValueChange(e);
                            }}
                        />
                        <label htmlFor="destination_store" className="text-lg font-bold">
                            Source store
                        </label>
                    </span>
                    {/* </Fieldset> */}
                </div>
            </div>
            {storeLabel?.length > 0 ? (
                <ProductStoreDatatable
                    // sourceStoreId={storeLabel?.[0]?.storeId}
                    sourceStoreId={destValue}
                    // destinationStoreId={destValue}
                    destinationStoreId={verifiedStoreData?.store_id}
                    destChangeValue={destChangeValue}
                    setDestChangeValue={setDestChangeValue}
                    storeName={labelName}
                    destinationName={verifiedStoreData?.store_name}
                />
            ) : null}
        </div>
    );
};

export default ProductStore;
