import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import { store } from './redux/storeConfig/store';
import reportWebVitals from './reportWebVitals';
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <Router>
                <AppWrapper></AppWrapper>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        style: {
                            padding: '16px',
                            border: '1px solid #713200',
                            color: '#713200'
                        },
                        error: {
                            duration: 800
                        },
                        success: { duration: 800 }
                    }}
                />
            </Router>
        </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
